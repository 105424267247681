import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>Site en Construction 🚧</h1>
        <p>Nous travaillons actuellement sur une nouvelle version du site.<br />
          Revenez bientôt pour découvrir nos nouveautés !</p>
        <a href="mailto:contact@alxmultimedia.com" className="button">Contactez-nous</a>
      </div>
    </div>
  );
}

export default App;
